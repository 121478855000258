@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.module {
  margin-bottom: pxToRem(16);

  @include larger-than-phone {
    margin-bottom: pxToRem(72);
    width: 351px;
  }
}

.title {
  padding: pxToRem(16);

  @include larger-than-phone {
    padding: pxToRem(16) 0;
  }
}

.listing {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--colors--off-white-background);
  color: var(--colors--primary-text);
  text-decoration-line: none;

  + .listing {
    margin-top: 8px;
  }
}

.thumbnail {
  width: 53px;
  height: 53px;
  flex-shrink: 0;
  border-radius: 4px;
}

.info {
  display: inline-flex;
  flex-direction: column;
}

.thumbnail + .info {
  margin-left: 10px;
}

.nav {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.caret {
  position: unset;
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  svg {
    color: var(--colors--primary-text);
    width: 10px;
    height: 10px;
  }
}

.dots {
  position: unset;

  button {
    background-color: var(--colors--primary-text);

    &[data-active="false"] {
      background-color: #d9d9d9;
      opacity: 1;
    }
  }
}
