@import "../mixins/button/index";
@import '../mixins/media-queries/index';

.Reactions {
  display: flex;

  .-reaction-button {
    @include button;
    margin-right: 8px;
    color: $colors--blue-highlight;

    &._round {
      border-color: $colors--blue-highlight;

      // accounting for sticky hover on mobile
      // when transitioning out of _super state
      &:hover {
        background-color: $colors--white-background;
        border-color: $colors--blue-highlight;
        color: $colors--blue-highlight;

        .-reaction-icon {
          path {
            fill: $colors--blue-highlight;
          }
        }
      }

      @media (hover:hover) {
        &:hover,
        &:active {
          background-color: $colors--blue-highlight;
          border-color: $colors--blue-highlight;
          color: $colors--white-background;

          .-reaction-icon {
            path {
              fill: $colors--white-background;
            }
          }
        }
      }

      &._super {
        // accounting for sticky hover on mobile
        // after transitioning to _super state
        &:hover {
          background-color: $colors--blue-highlight;
          border-color: $colors--blue-highlight;
          color: $colors--white-background;

          .-reaction-icon {
            path {
              fill: $colors--white-background;
            }
          }
        }

        @media (hover:hover) {
          &:hover,
          &:active {
            background-color: $colors--white-background;
            border-color: $colors--blue-highlight;
            color: $colors--blue-highlight;

            .-reaction-icon {
              path {
                fill: $colors--blue-highlight;
              }
            }
          }
        }
      }

      &._small {
        padding: 4px 15px;

        .-reaction-count {
          font-weight: 800;
          font-size: 14px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 3px;
        }

        .-reaction-icon {
          width: 15px;
          height: 15px;
          vertical-align: middle;
        }
      }
    }
  }
}
