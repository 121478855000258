@import "mixins/media-queries/index";
@import "mixins/type/index";
@import "../../../../styles/pxToRem.module.scss";

.form {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  padding-right: pxToRem(5);
  pointer-events: all;
  flex: 1;
}

.input {
  outline: 0;
  flex: 1;
  margin: 0;
  font-size: pxToRem(16);
  width: calc(100vw - pxToRem(275));
  text-overflow: ellipsis;
  @include type--base;

  @include larger-than-phone {
    width: 100%;
  }

  @include larger-than-tablet {
    @include type--body;
  }
  color: var(--colors--primary-text);

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: var(--colors--tertiary-text);
  }

  // override global type=search styles
  height: 100% !important;
  padding: 0 !important;
  background-color: transparent !important;
  border: 0 !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none;
}

.searchButton {
  flex: 0;
  height: pxToRem(32);
  min-width: pxToRem(75);
  display: none;

  @include larger-than-tablet {
    display: initial;
  }

  &.active {
    display: initial;
  }
}
