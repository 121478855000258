@import "../mixins/button/index";
@import "../mixins/field/index";
@import '../mixins/media-queries/index';


.StickyCommentsForm {
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom, 0);
  position: fixed;
  width: 100%;
  box-shadow: 0 0 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: $colors--white-background;
  z-index: $z-blog-sticky-comments-form;

  &:focus-within {
    padding-bottom: 0;
  }

  .-form {
    display: inherit;
    flex-direction: row;
    align-items: center;
    margin: 10px 13px;

    .avatar-container {
      width: 40px;
      height: 40px;
      margin-left: 5px;
      margin-right: 10px;
      align-self: flex-end;
    }

    .-comment-field {
      @include field;
      width: 100%;
      margin: 0;
      min-height: 40px;

      .--input {
        padding: 5px 15px;
        border-radius: 4px;
        resize: none;
        text-align: left;
        font-size: 16px;

        color: $colors--primary-text;
        min-height: 100%;
      }
    }
  }

  .-function-container {
    display: inherit;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 13px;
    min-height: 50px;

    .-comments-post-and-count {
      display: inherit;

      .-count {
        @include button;
        border: unset;

        &._small {
          padding: 4px 15px;
          border-radius: 5px;

          // accounting for sticky hover on mobile
          &:hover {
            background-color: $colors--white-background;
          }

          @media (hover:hover) {
            &:hover,
            &:active {
              background-color: $colors--white-background;;
            }
          }

          .-comment-icon {
            width: 15px;
            height: 15px;
            vertical-align: middle;
          }

          .-comment-count {
            font-weight: 800;
            font-size: 14px;
            display: inline-block;
            vertical-align: middle;
            color: $colors--medium-stroke;
            margin-left: 3px;
          }
        }
      }

      .-post {
        @include button;

        &._small {
          padding: 4px 15px;
          border-radius: 5px;

          // accounting for sticky hover on mobile
          &:hover {
            background-color: $colors--white-background;
          }

          @media (hover:hover) {
            &:hover,
            &:active {
              background-color: $colors--white-background;;
            }
          }

          .-post-text {
            font-weight: 800;
            font-size: 14px;
            display: inline-block;
            vertical-align: middle;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
