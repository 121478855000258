@use "sass:math";

@import "toPercent";

@mixin grid--guides($map) {
  $column-width: map-get($map, "column");
  $gutter-width: map-get($map, "gutter");
  $full-width: grid--realSpanWidth(1, 1, $map);

  background: repeating-linear-gradient(
    to right,
    #0079ff,
    #0079ff grid--toPercent(math.div($column-width, $full-width)),
    transparent grid--toPercent(math.div($column-width, $full-width)),
    transparent grid--toPercent(math.div($column-width + $gutter-width, $full-width))
  );
}
