@import "../../../app/assets/stylesheets/shared/z-index.scss";

.onboardingTooltip {
  position: absolute;
  display: flex;
  padding: 1rem;
  color: var(--colors--white-background);
  background-color: var(--colors--blue-highlight);
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  z-index: $z-tool-tips;
  text-align: left;

  &.hidden {
    visibility: hidden;
  }

  &::before {
    content: "\A";
    height: 3px;
    width: 0;
    border: 10px solid transparent;
    position: absolute;
  }

  &.left::before {
    left: 6px;
  }

  &.center::before {
    left: calc(50% - 12px);
  }

  &.right::before {
    right: 6px;
  }

  &.top::before {
    border-top-color: transparent;
    border-bottom-color: var(--colors--blue-highlight);
    top: -19px;
  }

  &.bottom::before {
    border-bottom-color: transparent;
    border-top-color: var(--colors--blue-highlight);
    top: initial;
    bottom: -19px;
  }
}
