@import "../../../../app/assets/stylesheets/mixins/media-queries/index";
@import "../../../../app/assets/stylesheets/shared/z-index";
@import "../../styles/pxToRem.module.scss";

.logo {
  line-height: 1em;
  z-index: $z-base-value;
  position: absolute;
  top: 0;
  left: 0;
  height: pxToRem(50);
  display: flex;
  align-items: center;
  margin-left: pxToRem(13);

  @include larger-than-phone {
    margin-left: 0;
    padding: 0;
    position: relative;
  }
}
