@import "../../../../styles/pxToRem.module.scss";

.item {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 0;
  padding: pxToRem(8) pxToRem(20);
  transition: background-color 250ms ease;
  cursor: pointer;
  &.active {
    background-color: var(--colors--light-stroke);
  }
  .itemLabel {
    flex: 1;
  }
}

.removeButton {
  border: 0;
  background: transparent;
  margin: 0;

  svg {
    width: 12px;
    height: 12px;
  }
}
