@import "mixins/type/index";
@import "storefront/components/styles/pxToRem.module.scss";

.title {
  width: 17%;
  text-align: left;
}

.subcategories {
  flex: 1 1 40%;
  max-height: pxToRem(375);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 17%;
  text-align: left;

  & + .subcategory {
    margin-top: pxToRem(12);
  }
}

.subcategory {
  display: inline-block;
  @include type--body;

  &:hover {
    color: var(--colors--blue-highlight);
  }

  & + .subcategory {
    margin-top: pxToRem(8);
  }
}

.subcategoriesTitle {
  display: inline-block;
  @include type--body-editorial;
  text-decoration: none;
  text-transform: capitalize;

  &:hover {
    color: var(--colors--blue-highlight);
  }

  & + .subcategory {
    margin-top: pxToRem(12);
  }
}
