@use "sass:math";

@import '../mixins/media-queries/index';
@import '../mixins/type/index';

#blog {
  .preview-articles-wrapper {
    .-article-card {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 15px 0px;
      margin: 0px 15px;
      border-top: 1px solid $colors--light-stroke;

      @include larger-than-phone {
        flex-direction: row;
        align-items: center;
      }

      @include larger-than-tablet {
        margin: initial;
        border: 1px solid $colors--light-stroke;
        padding: 15px;
      }

      .-img-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 230px;
        overflow: hidden;
        margin: 0 0 20px 0;

        @include larger-than-phone {
          width: 45%;
          min-width: 45%;
          margin: 0 30px 0 0;
          justify-content: center;
        }

        @include larger-than-tablet {
          height: 250px;
        }

        .-hero {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .-preview-articles-wrapper {
        text-align: left;

        .-franchise {
          @include type--primary-link;
          margin-bottom: 10px;
          color: $colors--accent;
        }

        .-title {
          @include type--headline-2;
          @include type--font-size-and-line-height(16px);
          margin-bottom: 15px;
        }

        .-subtitle {
          display: none;
          @include type--body;

          @include larger-than-tablet {
            display: block;
          }
        }

        .-bottom {
          display: none;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;

          @include larger-than-tablet {
            display: flex;
          }

          @include larger-than-laptop {
            flex-direction: row;
          }

          & > * {
            margin-right: 15px;
          }

          .-author {
            @include type--primary-link;
          }

          .-date {
            @include type--timestamp;
          }

          .-comment-count {
            @include type--body-caption;
            color: $colors--secondary-text;
          }
        }
      }
    }

    .-article-card + .-article-card {
      @include larger-than-tablet {
        margin-top: 20px;
      }
    }
  }

  .instagram-social {
    padding-top: 75px;
    border-top: 1px solid $colors--light-stroke;

    h2.follow-us {
      @include type--display-4;
      color: $colors--accent;
      text-align: center;
      width: 90%;
      margin: 0 auto 10px;

      @include larger-than-tablet {
        margin: 0 auto 20px;
      }
    }

    .instagram-accounts {
      text-align: center;
      width: 90%;
      margin: 0 auto 35px;

      @include larger-than-tablet {
        margin: 0 auto 50px;
      }

      .-instagram-account-link {
        @include type--primary-link;
        color: $colors--accent;
        cursor: pointer;
      }

      .-instagram-account-link + .-instagram-account-link {
        margin-left: 15px;
      }
    }
  }

  .container + .pagination {
    margin: 0px 15px;
    padding: 40px 0px;
    border-top: 1px solid $colors--light-stroke;

    @include larger-than-tablet {
      margin: 40px auto;
      padding: 0px;
      border-top: none;
    }
  }

  .pagination + .instagram-social {
    @include larger-than-tablet {
      margin-top: 60px;
    }
  }
}
