@import "../media-queries/index";
@import "relativeSpanWidth";
@import "default-config";

@mixin grid--span($is, $of, $config: $grid--default-config) {
  display: block;
  flex: 0 0 auto;

  width: grid--relativeSpanWidth($is, $of, map-get($config, "mobile"));
  @include larger-than-phone { width: grid--relativeSpanWidth($is, $of, map-get($config, "tablet")); }
  @include larger-than-tablet { width: grid--relativeSpanWidth($is, $of, map-get($config, "laptop")); }
  @include larger-than-laptop { width: grid--relativeSpanWidth($is, $of, map-get($config, "desktop")); }
}
