@import "../../mixins/media-queries/index";
@import "../../mixins/grid/index";
@import "../../mixins/grid/realSpanWidth";
@import "../../mixins/aspect-ratio";
@import "../../mixins/type/index";

.onehundred-2018 {
  $grid--mobile:  ( column: 54.16px, gutter: 0px, total-true-columns: 24 );
  $grid--tablet:  ( column: 54.16px, gutter: 0px, total-true-columns: 24 );
  $grid--laptop:  ( column: 54.16px, gutter: 0px, total-true-columns: 24 );
  $grid--desktop: ( column: 54.16px, gutter: 0px, total-true-columns: 24 );

  $grid--config: (
    mobile: $grid--mobile,
    tablet: $grid--tablet,
    laptop: $grid--laptop,
    desktop: $grid--desktop
  );

  // PRODUCT HOVER STYLING
  // NOTE: some products live inside --container elements, which constrain
  // the width - which means that the modifiers on the containers make the
  // modifiers on products have different meanings

  // Base typography - used when we are in a state that is not defined for desktop
  // sizes, for example if the user is on desktop but on a small screen
  .--product {
    .product {
      .--content-container {
        .--designer {
          @include type--display-3;
        }
        .--title {
          @include type--title;
        }
        .--size {
          @include type--button;
        }
        .--purchase-button {
          @include type--button;
        }
      }
    }
  }

  .--scrolling-video {
    display: none;
    @include larger-than-tablet {
      display: block;
      margin-bottom: 180px;
    }
    video {
      width: 100%;
    }
  }

  // Situations where product is "1/5th" on desktop
  // NOTE: there are some products that appear smaller than 1/5 (not in the
  // spec) so I styled these like the 1/5 style since they are the smallest
  .--container._only-on-desktop .--container._1040 .--container._half-container .--product._half,
  .--container._only-on-desktop .--container._780 .--product._one-third-on-desktop,
  .--container._three-fifths-on-desktop._only-on-desktop .--product._one-third-on-desktop,
  .--container._only-on-desktop._one-fifth-on-desktop .--product,
  .--product._half._one-third-on-tablet._325-max-on-desktop,
  .--container._only-on-desktop._one-fourth-on-desktop .--product {
    .product {
      .--content-container {
        width: 100%;
        height: 80%;
        .--designer {
          @include type--headline-2;
        }
        .--title {
          @include type--title;
        }
        .--size {
          @include type--button;
        }
        .--purchase-button {
          @include type--button;
        }
      }
    }
  }

  // Situations where product is "1/4" on desktop
  // NOTE: There is no 1/4 size in the spec, though they appear in the design (often), so
  // I decided to make these similar to the 1/5 style, minus the 100% width
  .--container._only-on-desktop._half-container .--product._half,
  .--product._half._one-fourth-on-desktop,
  .--container._half-on-desktop._only-on-desktop .--product._half,
  .--container._half._only-on-desktop .--product._half {
    .product {
      .--content-container {
        .--designer {
          @include type--display-3;
        }
        .--title {
          @include type--title;
        }
        .--size {
          @include type--button;
        }
        .--purchase-button {
          @include type--button;
        }
      }
    }
  }

  // Situations where product is "2/5ths" on desktop
  .--container._only-on-desktop._two-fifths-on-desktop .--product,
  .--container._only-on-desktop .--container._1040 .--product._half,
  .--product._half._two-fifths-on-desktop {
    .product {
      .--content-container {
        .--designer {
          @include type--display-3;
        }
        .--title {
          @include type--title;
        }
        .--size {
          @include type--button;
        }
        .--purchase-button {
          @include type--button;
          width: 70%;
        }
      }
    }
  }

  // Situations where product is "1/3" on desktop
  .--product._one-third-on-desktop,
  .--product._half._one-third-on-desktop {
    .product {
      .--content-container {
        .--designer {
          @include type--display-3;
        }
        .--title {
          @include type--title;
        }
        .--size {
          @include type--button;
        }
        .--purchase-button {
          @include type--button;
          width: 60%;
        }
      }
    }
  }

  // Situations where product is "1/2" on desktop
  .--product._half-on-desktop,
  .--container._only-on-desktop._half-container .--product,
  .--product._half._only-on-desktop {
    .product {
      .--content-container {
        .--designer {
          @include type--display-3;
        }
        .--title {
          @include type--title;
        }
        .--size {
          @include type--button;
        }
        .--purchase-button {
          @include type--button;
          width: 80%;
        }
      }
    }
  }

  .--header {
    display: flex;
    justify-content: center;
    align-content: space-between;
    text-align: center;
    max-width: 440px;
    margin: 0 auto 60px;

    .--header-link {
      @include grid--span(1, 3);
      @include type--form-label;
      align-self: center;
      padding-top: 8px;
      padding-bottom: 8px;
      text-transform: uppercase;

      &._product-view, &._lookbook {
        @include grid--span(2, 5);
      }

      &._lookbook {
        align-items: center;
        .--lookbook-icon {
          background-image: url("https://ds6dnbdlsnuyn.cloudfront.net/assets/placeholder.png");
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          height: 14px;
          margin-right: 8px;
          width: 16px;
        }
      }

      &._product-view {
        align-items: end;
        .--icon {
          display: block;
          margin-top: 1px;
          margin-right: 8px;
        }
      }

      &._full-view {
        align-items: end;
        position: relative;
        svg {
          top: 11px;
          position: absolute;
          left: 14px;
        }
      }

      &._share {
        align-items: center;
        @include grid--span(1, 5);

        .--share-text {
          display: none;
          @include larger-than-tablet {
            display: inline-block;
          }
        }
        .twitter-share, .facebook-share {
          line-height: 1;
        }
        .twitter-share {
          margin-right: 14px;
        }
        .facebook-share {
          margin-right: 6px;
        }
      }

      &._disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &._product-view, &._lookbook, &._share {
        display: flex;
        justify-content: center;
        @include larger-than-tablet {
          @include grid--span(1, 3);
          display: flex;
          justify-content: center;
          // NOTE: have to re-define display-flex to override grid--span display settings
        }
      }

    }
  }

  .Logo {
    background: #fff;
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
  }

  .--intro, .--lookbook {
    .Logo {
      padding: 3% 12% 4%;
      position: relative;
      width: 90%;
      z-index: $z-base-value;
      @include larger-than-tablet {
        width: 60%;
        padding-bottom: 0;
      }
      img {
        width: 100%;
      }
    }
  }


  .--intro {
    @include grid--wrapper($grid--config);
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 0 auto;

    .--video {
      position: relative;
      width: 100%;
      aspect-ratio: 16, 9;
      height: 53.5vw;
      max-height: 736px;
      @include larger-than-phone {
        height: 55vw;
      }
      @include larger-than-tablet {
        height: 55.7vw;
      }
      &:before {
        padding-top: 0px; // NOTE(lg): wat
      }
      iframe {
        border: 0px;
      }
    }

    .--logo {
      @include larger-than-tablet {
        margin-top: -5%;
      }
    }

    .--video-overlay {
      position: absolute;
      top: 50%;
    }

    .--text {
      width: 90%;
      padding-bottom: 5%;

      @include larger-than($max-tablet-width) {
        width: 80%;
        padding: 5%;
        display: flex;
      }

      p {
        @include type--base;
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        @include larger-than($max-tablet-width) {
          flex: 1;
          font-size: 20px;
          line-height: 32px;

          &:nth-child(odd) {
            padding-right: 10px;
          }
          &:nth-child(even) {
            padding-left: 10px;
          }
        }
      }
    }

  }

  .--drop, .--grid-view {
    @include grid--wrapper($grid--config);
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    .--loading {
      padding: 2% 4%;
      line-height: 65px;
    }
  }

  .--lookbook {
    width: 100%;
    margin-top: 10px;

    .Logo {
      margin-bottom: 3%;
    }
  }

  .--filler {
    width: 100%;

    &._one-third-height {
      height: 33%;
    }
    &._half-height {
      height: 50%;
    }
  }

  .--look {
    @include grid--span(1, 1, $grid--config);
    box-sizing: border-box;
    margin-top: 1%;

    .--photo {
      width: 100%;
      height: 100%;
    }
  }
  .--product {
    @include grid--span(1, 1, $grid--config);
    box-sizing: border-box;
    .product {
      position: relative;
      line-height: 0;
      .--photo {
        width: 100%;
        height: 100%;
      }
      .--overlay {
        display: none;
        @include larger-than-tablet {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: absolute;
          z-index: $z-base-value;
          width: 100%;
          height: 100%;
          top: 0;
          cursor: pointer;

          .--content-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: #fff;
            position: relative;
            box-sizing: border-box;
            opacity: 0;
            transition: opacity 0.35s ease-in-out;
            padding: 6% 8%;
            width: 85%;
            height: 68%;

            &::before, &::after {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              transition: all 0.35s;
              opacity: 0;
              content: '';
              box-sizing: border-box;
            }

            &::before {
              transform: scale(0, 1);
              border-top: 1px solid #000;
              border-bottom: 1px solid #000;
            }

            &::after {
              transform: scale(1, 0);
              border-left: 1px solid #000;
              border-right: 1px solid #000;
            }

            .--content {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              transition: all 0.35s ease-in-out;
              opacity: 0;
              transform: translateY(100%);
              text-align: center;
              max-width: 100%;
              width: 100%;
            }

            .--designer, .--title, .--size, .--purchase-button {
              line-height: initial;
            }

            // FIXME: these are just guesses for now
            .--designer {
              margin-bottom: 2%;
            }
            .--title {
              margin-bottom: 8%;
            }
            .--size {
              margin-bottom: 10%;
            }

            .--purchase-button {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 2px;
              background-color: #000;
              width: 80%;
              padding-top: 14px;
              padding-bottom: 14px;
              color: $colors--white-background !important;
              &:hover {
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
              }
              &._sold {
                background-color: $colors--white-background;
                color: $colors--primary-text !important;
                border: 1px solid $colors--primary-text;
              }
            }
          }

          &:hover {
            .--content-container {
              opacity: 0.9;
              &::before, &::after {
                opacity: 1;
                transform: scale(1);
              }
              .--content {
                opacity: 1;
                transform: translateY(0);
              }
            }
          }
        }
      }

      &._sold {
        opacity: 0.8;
        @include larger-than-tablet {
          opacity: 1;
        }
      }
    }
  }

  .--drop, .--grid-view {
    &._before {
      min-height: 60vw;
      max-height: 100vh;
      margin-bottom: 90px;
      padding-left: 14px;
      padding-right: 14px;
      box-sizing: border-box;
      @include larger-than($max-phone-width) {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 120px;
      }
      @include larger-than($max-tablet-width) {
        margin-bottom: 175px;
        padding: 0;
      }

      h2 {
        text-align: center;
        font-size: 3.4rem;
        line-height: 4rem;
        margin-bottom: 2.3rem;
      }

      @include larger-than($max-phone-width) {
        &:nth-child(odd) {
          .--countdown-container {
            background-size: 50%;
          }
        }
        &:nth-child(even) {
          .--countdown-container {
            background-size: 70%;
          }
        }
      }

      .--countdown-container {
        @include grid--span(1, 1, $grid--config);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include larger-than($max-phone-width) {
          justify-content: center;
        }

        .--drop-title {
          @include type--display-2;
        }

        .--countdown-title {
          @include type--display-3;
          text-transform: uppercase;
        }

        .--countdown {
          @include grid--span(7, 12, $grid--config);
          background-color: $colors--primary-text;
          color: $colors--white-background;
          padding: 2% 4%;
          display: flex;
          border-radius: 4px;
          justify-content: space-between;
          margin: 0 auto;

          .--unit {
            flex: 1;
            .--number {
              @include type--base;
              font-size: 24px;
              @include larger-than($max-phone-width) {
                margin-bottom: 4px;
              }
              @include larger-than($max-tablet-width) {
                margin-bottom: 12px;
                font-size: 54px;
                line-height: 65px;
              }
            }
            .--name {
              @include type--body;
              // or type--button ?
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .--section {
      @include grid--wrapper($grid--config);
      justify-content: center;
      width: 100%;
      max-width: 100%;
      margin: 0 auto 160px auto;

      .--editorial-image {
        @include grid--span(1, 1, $grid--config);
        box-sizing: border-box;
        .--photo {
          width: 100%;
          height: 100%;
        }
      }

      .--text {
        @include grid--span(1, 1, $grid--config);
        box-sizing: border-box;
        width: 92%;
        padding-left: 5%;
        padding-right: 5%;

        @include larger-than($max-tablet-width) {
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          &._half-text-on-desktop {
            width: 50%;
          }
          &._two-fifths-text-on-desktop {
            width: 40%;
          }
        }

        h2 {
          @include type--display-1;
          font-size: 32px;
          line-height: 42px;
          @include larger-than($max-phone-width) {
          }
          @include larger-than($max-tablet-width) {
            font-size: 48px;
            line-height: 64px;
          }
        }

        p {
          @include type--base;
          font-weight: 300;
          font-size: 16px;
          line-height: 26px;
          @include larger-than($max-tablet-width) {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }

      .--container {
        height: auto;
        @include larger-than($max-tablet-width) {
          display: flex;
          flex-flow: wrap;
          box-sizing: border-box;
          width: 100%;
          margin: 0 auto;
        }

        &._three-fifths {
          @include larger-than($max-tablet-width) {
            width: 60%; // HACK this is to avoid the display: block applied in the grid
          }
        }

        &._half-container {
          @include larger-than($max-tablet-width) {
            width: 50%; // HACK this is to avoid the display: block applied in the grid
          }
        }

        &._two-fifths {
          @include larger-than($max-tablet-width) {
            width: 40%; // HACK this is to avoid the display: block applied in the grid
          }
        }

        &._1040 {
          @include larger-than($max-tablet-width) {
            max-width: 1040px;
          }
        }
        &._975 {
          @include larger-than($max-tablet-width) {
            max-width: 975px;
          }
        }
        &._780 {
          @include larger-than($max-tablet-width) {
            max-width: 780px;
          }
        }
      }

    }

    .--editorial-image, .--product, .--text {
      height: fit-content;
    }

    .--editorial-image, .--product, .--text, .--container {
      &._half {
        @include grid--span(1, 2, $grid--config);
      }

      &._half-on-tablet {
        @include larger-than($max-phone-width) {
          @include grid--span(1, 2, $grid--config);
        }
      }

      &._middle-on-tablet {
        @include larger-than($max-phone-width) {
          margin: 0 auto;
        }
      }

      &._one-third-on-tablet {
        @include larger-than($max-phone-width) {
          @include grid--span(1, 3, $grid--config);
        }
      }

      &._two-thirds-on-tablet {
        @include larger-than($max-phone-width) {
          @include grid--span(2, 3, $grid--config);
        }
      }

      &._margin-bottom-on-desktop {
        @include larger-than($max-tablet-width) {
          margin-bottom: 3%;
        }
      }

      &._half-on-desktop {
        @include larger-than($max-tablet-width) {
          @include grid--span(1, 2, $grid--config);
        }
      }

      &._one-third-on-desktop {
        @include larger-than($max-tablet-width) {
          @include grid--span(1, 3, $grid--config);
        }
      }

      &._two-thirds-on-desktop {
        @include larger-than($max-tablet-width) {
          @include grid--span(2, 3, $grid--config);
        }
      }

      &._one-fourth-on-desktop {
        @include larger-than($max-tablet-width) {
          @include grid--span(1, 4, $grid--config);
        }
      }

      &._three-fourths-on-desktop {
        @include larger-than($max-tablet-width) {
          @include grid--span(3, 4, $grid--config);
        }
      }

      &._325-max-on-desktop {
        @include larger-than($max-tablet-width) {
          max-width: 325px;
        }
      }

      &._one-fifth-on-desktop {
        @include larger-than($max-tablet-width) {
          @include grid--span(1, 5, $grid--config);
        }
      }

      &._two-fifths-on-desktop {
        @include larger-than($max-tablet-width) {
          @include grid--span(2, 5, $grid--config);
        }
      }

      &._three-fifths-on-desktop {
        @include larger-than($max-tablet-width) {
          @include grid--span(3, 5, $grid--config);
        }
      }

      &._only-on-tablet {
        display: none;
        @include larger-than($max-phone-width) {
          display: inherit;
        }
        @include larger-than($max-tablet-width) {
          display: none;
        }
      }

      &._only-on-desktop {
        display: none;
        @include larger-than($max-tablet-width) {
          display: inherit;
        }
      }

      &._not-on-desktop {
        @include larger-than($max-tablet-width) {
          display: none;
        }
      }
    }
  }

  .Thank-you {
    @include grid--wrapper($grid--config);
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    .--thank-you-image {
      width: 100%;
      margin-bottom: 20px;
      align-self: baseline;
    }
    .--text-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      @include larger-than-tablet {
        flex-direction: row;
      }
      .Logo {
        margin: 0 20px 10px;
        text-align: center;
        width: 100%;
        flex: 1;
        @include larger-than-tablet {
          text-align: left;
          margin: 0;
        }

        img {
          width: 70%;
          @include larger-than-tablet {
            width: 50%;
          }
        }
      }
      p {
        flex: 1;
        @include type--display-3;
        margin: 0;
        text-align: right;
      }
    }
  }
}
