@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

.heroLoading {
  min-height: 70vh;
  margin-bottom: 10px;
}

.tickerLoading {
  height: 40px;
  width: 100%;
  margin-bottom: 50px;
}
.gridLoading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: pxToRem(24) 0;
  margin-bottom: pxToRem(50);

  @include larger-than-phone {
    gap: pxToRem(50) pxToRem(72);
    max-width: pxToRem(1200);
    margin-left: auto;
    margin-right: auto;
  }
}

.agcCollectionLoading {
  height: 425px;
  width: 351px;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
