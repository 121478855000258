@import "../../../../../app/assets/stylesheets/mixins/media-queries/index";
@import "../../../../../app/assets/stylesheets/mixins/type/index";
@import "../../../../../app/assets/stylesheets/mixins/button/index";
@import "../../../../../app/assets/stylesheets/shared/z-index";
@import "../../../styles/pxToRem.module.scss";

.Page-Header-Menu {
  position: absolute;
  right: 0;
  top: 0;
  // adding min-width to prevent layout shift once inner elements are rendered
  min-width: 132.34px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;

  @include larger-than-phone {
    position: static;
    margin-left: auto;
  }

  @include larger-than-tablet {
    margin-left: auto;
    padding-left: pxToRem(28);
  }

  &._logged-out {
    .--button {
      display: none;

      @include larger-than-laptop {
        display: block;
      }
    }

    .--link._primary {
      display: block;
      margin-right: pxToRem(10);

      @include larger-than-tablet {
        margin-right: 0;
      }
    }
  }

  .--button {
    @include button;
    text-decoration: none;
    white-space: nowrap;
    display: none;
    -webkit-font-smoothing: auto;

    &:hover {
      body._grailed & {
        color: var(--colors--blue-highlight);
      }
    }
  }

  .--link {
    margin-left: pxToRem(28);
  }
  .--link + .--button {
    margin-left: pxToRem(28);
  }
  .--button + .--button {
    margin-left: pxToRem(28);
  }

  .--link {
    display: none;
    padding: pxToRem(10) 0;
    @include type--primary-link;
    color: var(--colors--primary-text);
    text-decoration: none;
    white-space: nowrap;

    &.-sign-up {
      display: inline-block;
      @include larger-than-laptop {
        display: none;
      }
    }

    &:hover {
      body._grailed & {
        color: var(--colors--blue-highlight);
      }
    }

    @include larger-than-laptop {
      display: block;
    }

    .--extra {
      display: none;

      @include larger-than-tablet {
        display: inline;
      }
    }
  }

  .-mygrails {
    display: block;
    cursor: pointer;
    position: relative;
    width: pxToRem(45);
    height: pxToRem(50);
    margin-left: pxToRem(15);

    .-heart-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        .heart {
          body._grailed & {
            g {
              fill: var(--colors--blue-highlight);
            }
            path {
              stroke: var(--colors--blue-highlight);
            }
          }
        }
      }

      .heart {
        transform: scale(1.85);

        g {
          fill: var(--colors--primary-text);
        }
        path {
          stroke: var(--colors--primary-text);
        }
      }
    }
  }

  .-mygrails + .--profile {
    margin-left: pxToRem(15);
  }

  .--profile {
    position: relative;
    width: pxToRem(35);
    height: pxToRem(35);
    display: none;

    @include larger-than-laptop {
      display: block;
    }

    .--image {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &._notified {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: pxToRem(10);
        height: pxToRem(10);
        border-radius: 100%;
        background-color: var(--colors--alert);
      }
    }
  }

  .-menu-container {
    position: relative;
    cursor: pointer;
    opacity: 1;
    width: pxToRem(45);
    height: pxToRem(50);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: pxToRem(10);

    &._hidden {
      opacity: 0;
    }

    &._notifications {
      &:after {
        content: "";
        position: absolute;
        top: pxToRem(15);
        right: pxToRem(10);
        width: pxToRem(8);
        height: pxToRem(8);
        border-radius: 100%;
        background-color: var(--colors--alert);

        @include larger-than-laptop {
          top: pxToRem(3);
          right: pxToRem(-3);
        }
      }
    }

    @include larger-than-laptop {
      display: none;
    }

    .-menu-icon {
      width: 22px;
    }
  }
}

.SellButton {
  display: none;

  @include larger-than-laptop {
    display: block;
  }
}

.DropdownMenu {
  opacity: 0;
  visibility: hidden;
}

.--profile:hover {
  .DropdownMenu {
    opacity: 1;
    visibility: visible;
  }
}
