@import "mixins/media-queries/index";
@import "storefront/components/styles/pxToRem.module.scss";

.link {
  text-decoration: none;
  color: var(--colors--primary-text);
  display: inline-block;
}

.title {
  padding: pxToRem(16);

  @include larger-than-phone {
    padding: pxToRem(16) 0;
  }
}

.images {
  display: grid;
  grid-template-columns: pxToRem(130) pxToRem(65) pxToRem(65) pxToRem(130);
  grid-template-rows: pxToRem(240) pxToRem(130);
  grid-template-areas:
    "img1 img1 img2 img2"
    "img3 img4 img4 img5";
  gap: pxToRem(1);

  @include larger-than-phone {
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "img1 img2"
      "img3 img4";
  }
}

.image-1,
.image-2 {
  width: pxToRem(194);
  height: pxToRem(240);
}

.image-3,
.image-4 {
  width: pxToRem(130);
  height: pxToRem(130);
}

.image-1,
.image-2,
.image-3,
.image-4,
.image-5 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include larger-than-phone {
    width: pxToRem(185);
    height: pxToRem(211);
  }
}

.image-1 {
  grid-area: img1;
}

.image-2 {
  grid-area: img2;
}

.image-3 {
  grid-area: img3;
}

.image-4 {
  grid-area: img4;

  .img {
    @include larger-than-phone {
      filter: brightness(0.6);
    }
  }

  .viewMore {
    display: none;

    @include larger-than-phone {
      display: block;
    }
  }
}

.image-5 {
  grid-area: img5;

  @include larger-than-phone {
    display: none;
  }

  .img {
    filter: brightness(0.6);
  }

  .viewMore {
    @include larger-than-phone {
      display: none;
    }
  }
}

.viewMore {
  position: absolute;
  color: white;
  font-family: var(--video-font);
  font-size: pxToRem(11);
  font-weight: 500;
  letter-spacing: pxToRem(0.44);
  text-transform: uppercase;
}
