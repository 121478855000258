@import "mixins/media-queries/index";

.slide {
  margin-bottom: 0px;
  min-height: 336px;

  @include larger-than-phone {
    min-height: 390px;
  }
}

.carousel {
  min-width: 100vw;
}
