@import "stylesheets/shared/z-index";
@import "storefront/components/styles/pxToRem.module.scss";
@import "mixins/media-queries/index";

.menu {
  background-color: var(--colors--white-background);
  border: 1px solid var(--colors--light-stroke);
  box-sizing: content-box;
  display: none;
  padding-top: pxToRem(10);
  padding-bottom: pxToRem(15);
  position: absolute;
  right: 0;
  top: 125%;
  transition: opacity 250ms ease 50ms, visibility 250ms ease 50ms;
  width: 145px;
  z-index: $z-header-link;

  @include larger-than-laptop {
    display: block;
  }

  > * {
    display: block;
    text-transform: uppercase;
    margin: 0 pxToRem(20);
  }
}

.link {
  color: var(--colors--primary-text);
  font-size: 12px;
  text-decoration: none;
  line-height: initial;

  &:hover {
    color: var(--colors--blue-highlight);
  }

  &.primary {
    font-family: "Video", monospace;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: pxToRem(5);
    margin-bottom: pxToRem(15);
    align-items: center;
    overflow: hidden;
    display: block;
    position: relative;
    padding-right: 1rem;

    svg {
      position: absolute;
      top: 4px;
      right: 0;
    }
  }
}

.link + .link {
  margin-top: pxToRem(10);
}

.divider,
.usernameDivider {
  border-top: 1px solid var(--colors--light-stroke);
}

.divider {
  margin-top: pxToRem(15);
  margin-bottom: pxToRem(15);
}

.usernameDivider {
  margin: pxToRem(10) 0 pxToRem(15) 0;
}

.notifications {
  color: var(--colors--alert);
  margin-left: pxToRem(5);
}

.link.signOut {
  color: var(--colors--secondary-text);
  font-size: 10px;

  &:hover {
    color: var(--colors--blue-highlight);
  }
}

.caret {
  width: 10px;
  height: 7px;
}
