// Base Page Content 0 - 99
$z-before: 0;

$z-base-value: 1;
$z-base-elem-value: 10;
$z-links: 20;

$z-one-hundred-background: 40;
$z-one-hundred-image-overlay: 50;

$z-feed-listing-card-overlay: 2;
$z-feed-listing-card-admin-overlay: 3;
$z-feed-and-filters: 10;
$z-feed-bar-wrapper: 20;
$z-popovers: 70;

$z-carousel-arrow: 4;

$z-tool-tips: 80;

$z-homepage-hero-article-gradient: 2;
$z-homepage-hero-article-content: 3;

$z-blog-article: 1;
$z-blog-article-image: 20;
$z-blog-article-gradient: 30;
$z-blog-tags: 40;
$z-blog-article-content: 50;
$z-blog-article-banner: 60;
$z-blog-article-icon: 70;

$z-drop-down: 30;
$z-drop-down-toggle: 30;
$z-drop-down-title: 40;

// Flash Messages 100 - 199
$z-feed-settings-sticky: 120;
$z-blog-sticky-comments-form: 130;
$z-flash-message: 150;

// Header, Footer 200 - 299
$z-header-base: 220;
$z-header-link: 250;

// Modals 300 - 399
$z-general-overlay: 350;
$z-settings-window: 350;
