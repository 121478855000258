@import "../../../../app/assets/stylesheets/mixins/media-queries/index";
@import "../../../../app/assets/stylesheets/shared/z-index";
@import "../../styles/pxToRem.module.scss";

.pageHeader {
  --web-header-height-mobile: 49px;
  --web-header-height: 121px;

  flex-grow: 0;
  width: 100%;
  margin-bottom: 0;
  min-height: pxToRem(49);
  z-index: $z-header-base;

  @include larger-than-phone {
    min-height: pxToRem(76);
  }

  @include larger-than-tablet {
    min-height: var(--web-header-height);
  }

  &.stickyHeader {
    position: sticky;
    top: 0;
  }

  .frame {
    max-height: var(--web-header-height-mobile);
    position: relative;

    @include larger-than-phone {
      max-height: var(--web-header-height);
    }
  }

  .wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: var(--spacing-16);
    border-bottom: 1px solid var(--colors--light-stroke);
    height: var(--web-header-height-mobile);
    box-sizing: border-box;
    z-index: $z-header-base;
    width: 100%;
    position: absolute;

    @include larger-than-phone {
      height: pxToRem(76);
    }

    :global(body._grailed) & {
      background-color: var(--colors--white-background);
    }

    @include larger-than-laptop {
      padding: var(--spacing-16) var(--spacing-32);
    }

    &.stickyWrapper {
      position: fixed;
      top: 0;
    }
  }

  .content {
    display: flex;
    width: 100%;

    @include larger-than-phone {
      margin-top: 0;
      max-width: pxToRem(1200);
      align-items: center;
    }

    @include larger-than-tablet {
      margin: auto;
    }
  }
}
