@import "storefront/components/styles/pxToRem.module.scss";
@import "mixins/media-queries/index";

.ticker {
  height: pxToRem(40);
  background-color: var(--colors--primary-text);
  color: var(--colors--white-background);
  font-size: pxToRem(12);
  font-family: "Video", monospace;
  font-weight: 700;
  line-height: 104%;
  letter-spacing: pxToRem(0.48);
  text-transform: uppercase;
  margin-bottom: pxToRem(8);

  @include larger-than-phone {
    margin-bottom: pxToRem(42);
  }

  :global(.rfm-marquee) :global(.rfm-child) {
    margin-left: pxToRem(62);
  }

  &:global(.rfm-marquee-container) {
    width: 100vw;
    overflow-x: visible;
  }
}
