@import "../../../../styles/pxToRem.module.scss";

.-category._browse-by-category {
  position: relative;

  .-toggle {
    vertical-align: pxToRem(2);
    margin-left: pxToRem(4);
  }

  .-category-popover {
    padding-top: pxToRem(20);
  }

  .-dropdown {
    .-category-popover:hover {
      color: var(--colors--blue-highlight);

      .-popover {
        visibility: visible;

        .-link {
          color: var(--colors--primary-text);

          &:hover {
            color: var(--colors--blue-highlight);
          }
        }
      }
    }

    .-popover {
      width: pxToRem(185);
      position: absolute;
      background-color: var(--colors--white-background);
      left: pxToRem(183);
      padding: pxToRem(15) 0;
      top: pxToRem(-1);
      opacity: 1;
      visibility: hidden;
      border: 1px solid var(--colors--light-stroke);
      box-sizing: border-box;

      &.-lower {
        top: 39px;
      }
    }
  }
}
