@import "../../../../styles/pxToRem.module.scss";

.panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  left: 0;
  width: 100%;
  position: absolute;
  background-color: var(--colors--white-background);
  z-index: 250;
  box-shadow: 0 0.2rem 0.2rem rgb(0 0 0 / 50%);
  top: 4.5rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.collectionSection {
  padding-bottom: pxToRem(17);
}

.header {
  padding: 0 pxToRem(20) pxToRem(15) pxToRem(20);
}
