@import "../shared/config/_helpers.scss";
@import "../mixins/type/index";
@import "../mixins/media-queries/index";

body.articles.show {
  padding-bottom: 0px;
}

body.webview {
  .social-sharing-widget {
    display: none;
  }
}

.article-wrapper {
  p:not(.feed-item p) {
    font-family: "Times New Roman", serif;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.025rem;

    &:last-child {
      margin-bottom: 4rem;
    }
  }
}

#blog {
  .long2 .hero {
    margin-bottom: 40px !important;
  }

  p {
    a,
    a:visited {
      color: $colors--info;
      text-decoration: underline;

      &:hover {
        color: $colors--info;
      }
    }
  }

  //
  // HOME PAGE START
  //

  h1 {
    margin-bottom: 0;
    text-align: center;
  }

  .section {
    margin-bottom: 8rem;

    &:first-child {
      margin-bottom: 6rem;
    }
  }
  .push {
    margin-top: 6rem;
  }

  .social-sharing-widget {
    display: none;
  }

  .listings .feed .row:last-child {
    margin-bottom: 0;
  }

  text-align: center;

  * {
    box-sizing: border-box;
  }

  .container {
    flex: 1;
    padding: 0;
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;

    p:not(.feed-item p) {
      margin-top: 0;
      @include type--body;
      color: var(--colors--primary-text);

      &.sub-title {
        text-transform: none;
        font-family: var(--sub-title-font);
      }
    }

    &.home,
    &.tagged-articles {
      max-width: 1200px;
    }

    &.tagged-articles {
      img {
        max-width: 1200px;
      }

      h1 {
        font-size: 2.4rem;
      }
    }
  }

  .home {
    text-align: left;

    .feature-and-articles-wrapper {
      @include larger-than-tablet {
        width: 80%;
        padding: 20px;
      }

      .primary-article {
        display: block;
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 1rem;

        .-article-wrapper {
          position: relative;

          .-hero {
            width: 100%;
            height: 250px;
            background-size: cover;
            background-position: center;

            @include larger-than-phone {
              height: 375px;
            }
          }

          .-gradient {
            background-image: linear-gradient(
              rgba(46, 30, 30, 0.1),
              rgba(0, 0, 0, 0.6)
            );
            position: absolute;
            width: 100%;
            height: 100%;
          }

          .-primary-article-content {
            position: absolute;
            padding: 10px;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .-franchise {
              @include type--large-link;
              color: $colors--white-background;
            }

            .-title {
              @include type--display-4;
              color: $colors--white-background;
              font-size: 20px;

              @include larger-than-phone {
                @include type--display-3;
                color: $colors--white-background;
              }
            }

            .-read {
              @include button;
              border: 1px solid $colors--primary-text;
              width: min-content;
              white-space: nowrap;
              margin: 0px auto;
            }

            .-franchise + .-title,
            .-title + .-read {
              margin-top: 20px;
            }
          }
        }
      }

      .content-wrapper {
        position: relative;
      }

      .primary-article + .content-wrapper {
        margin-top: 30px;
      }
    }

    .main {
      text-align: left;
      padding-top: 2px;
      h2,
      h2:not(.jumbo) {
        color: $colors--primary-text;
        font-weight: 600;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
      }

      h4,
      h4:not(.jumbo) {
        font-size: 1.6rem;
        font-weight: normal;
      }
    }

    h1 {
      line-height: 1;
      margin: 0;
      text-align: left;
    }
  }

  .tagged-articles {
    .filtered-articles-wrapper {
      @include larger-than-tablet {
        width: 80%;
        padding: 20px;
      }

      h1.jumbo {
        @include type--display-2;
        color: $colors--primary-text;
        text-align: left;
        margin: 20px auto 0;
        width: 90%;

        @include larger-than-tablet {
          margin: auto;
          width: 100%;
        }
      }

      .-filter-description {
        @include type--body;
        text-align: left;
        margin: 15px auto 0;
        width: 90%;

        @include larger-than-tablet {
          width: 100%;
        }
      }

      .-filter-description + .content-wrapper,
      h1.jumbo + .content-wrapper {
        margin-top: 40px;
      }

      .content-wrapper {
        margin-top: 0;
      }
    }
  }

  .article-and-comments-header {
    .-social-title-search-wrapper {
      padding: 20px;

      .-masthead {
        text-align: left;

        .-link {
          .-img {
            width: 88px;

            @include larger-than-tablet {
              width: 120px;
            }
          }
        }
      }

      .-mobile-filter {
        display: none;
      }

      .-franchises-list {
        display: none;
      }

      .-social-icons {
        display: none;
      }
    }
  }

  .home,
  .tagged-articles {
    @include larger-than-tablet {
      display: flex;
      justify-content: space-around;
    }

    .-social-title-search-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      align-items: center;
      border-bottom: 1px solid $colors--light-stroke;

      @include larger-than-tablet {
        display: block;
        border-bottom: none;
        width: 20%;
      }

      .-masthead {
        @include larger-than-tablet {
          text-align: left;
        }

        .-link {
          .-img {
            width: 88px;

            @include larger-than-tablet {
              width: 120px;
            }
          }
        }
      }

      .-mobile-filter {
        @include larger-than-tablet {
          display: none;
        }

        .-franchise-article {
          @include type--primary-link;
          margin: 0px;
        }

        .-franchise-selector {
          @include type--primary-link;
          width: 130px;
          border-color: $colors--light-stroke;
          margin: 0px;

          &:focus {
            border-color: $colors--primary-text;
          }
        }
      }

      .-franchises-list {
        display: none;

        @include larger-than-tablet {
          display: block;
          list-style: none;
          margin-top: 40px;
        }

        .-franchise-list-item {
          @include type--primary-link;
          text-align: left;
          margin: 0px;

          .-franchise-link {
            &.selected {
              padding-bottom: 3px;
              border-bottom: 1px solid $colors--primary-text;
            }
          }
        }

        .-franchise-list-item + .-franchise-list-item {
          margin-top: 20px;
        }
      }

      .-social-icons {
        display: none;

        @include larger-than-tablet {
          display: block;
          text-align: left;
          border-top: 1px solid $colors--light-stroke;
          padding-top: 20px;
        }

        .-title {
          @include type--form-label;
          color: $colors--light-stroke;
        }

        .-icons-wrapper {
          a {
            img {
              width: 15px;
              height: 15px;
            }
          }

          a + a {
            margin-left: 15px;
          }
        }

        .-title + .-icons-wrapper {
          margin-top: 20px;
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      &:not(.jumbo) {
        font-family: $title-font;
        font-size: 4.8rem;
        color: $colors--white-background;
        font-weight: bold;
        text-transform: none;
      }
    }
  }

  // END OF HOME PAGE

  //
  // BASE ARTICLE START
  //

  .article-wrapper {
    .article-top-section {
      .title-container._only-on-mobile {
        margin: 0px 4% 12px 4%;

        h1.title {
          display: block;
          max-width: 100%;
          margin-bottom: 0px;

          p {
            @include larger-than-phone {
              line-height: 48px;
            }
          }
        }

        .info {
          text-align: left;
          font-size: 12px;
          font-family: $title-font;
        }

        @include larger-than-phone {
          display: none;
        }
      }

      .mobile-photo-credit-and-share._only-on-mobile {
        display: block;
        @include larger-than-phone {
          display: none;
        }
      }

      h1.title {
        display: none;
        @include larger-than-phone {
          display: block;
          margin-bottom: 4rem;
        }
      }

      p.tags {
        display: none;
        @include larger-than-phone {
          display: block;
        }
      }

      .main-content-wrapper {
        .info {
          display: none;
          @include larger-than-phone {
            display: block;
          }
        }
      }
    }

    img.hero {
      width: 100%;
      display: block;

      &.section {
        margin-bottom: 0px;
        @include larger-than-phone {
          margin-bottom: 6rem;
        }
      }
    }

    .hero-label {
      margin-bottom: 6rem;
      p {
        font-size: 11px;
        font-family: $title-font;
        font-style: italic;
        color: $colors--gray-banner;
      }
    }

    .youtube-video {
      text-align: center;
      margin: 0 auto 4rem;

      @include larger-than-phone {
        margin: 0 auto 8rem;
      }
    }

    .container {
      max-width: 1200px;
      text-align: left;
    }

    h1.title * {
      text-align: left;
      letter-spacing: -0.5px;
      font-size: 19px;
      margin: 0;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: bold;
      }

      @include larger-than-phone {
        font-size: 48px;
        margin: 0 auto 3rem auto;
        max-width: 1200px;
        letter-spacing: -1px;
        text-align: center;
      }
    }

    .social-sharing-widget-container {
      display: flex;
      align-items: center;
    }

    .mobile-photo-credit-and-share {
      display: flex;
      justify-content: space-between;
      margin: 0 4%;
      padding-bottom: 9px;
      border-bottom: 1px solid #c3c3c3;
      margin-bottom: 3rem;

      .photo-credit {
        height: 16px;
        display: flex;
        align-items: center;

        p {
          display: inline;
          font-size: 12px;
          font-family: $title-font;
          margin: 0;
        }
      }

      .social-sharing-widget {
      }
    }

    .publish-date,
    .author {
      font-size: 1.2rem;
      margin-bottom: 2rem !important;
    }

    .tags {
      font-family: $title-font;
      font-size: 1.4rem;

      .label {
        font-weight: bold;
      }

      a {
        font-style: italic;
      }
    }

    .listings {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .feed {
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;

        @include larger-than-tablet {
          width: 90%;
          padding: 27px 27px 0;
        }
      }

      .feed-item {
        .listing-age {
          @include type--timestamp-card;
          line-height: 14px;
          color: $colors--secondary-text;
        }

        .listing-designer {
          @include type--designer-name-card;
          margin-bottom: 0;
        }

        .listing-size {
          @include type--size-card;
          margin-bottom: 0;
        }

        .listing-title {
          @include type--item-name-card;
          margin: 0;
        }

        .listing-price {
          p {
            @include type--price-card;
            margin-bottom: 0;

            &.new-price {
              color: $colors--alert;
            }

            &.original-price {
              color: $colors--primary-text;

              &.strike-through {
                @extend .strike-through;
                color: $colors--secondary-text;
              }
            }

            &.sold-price {
              color: $colors--success;
            }
          }
        }
      }

      .list-item {
        flex: 1 0 24rem;
        margin-bottom: 3rem;
        max-width: 24rem;
      }
    }

    .budgets {
      display: flex;
      justify-content: space-between;
      @include larger-than-phone {
        padding: 0 4%;
      }

      .budget {
        flex: 1;
        max-width: 506px;

        .header {
          width: 100%;
          display: flex;
          .title {
            flex: 1;
          }

          .average {
            flex: 1;
            text-align: right;
          }
        }

        .feed-item {
          height: 100%;
          width: 100%;

          .listing-designer-and-size {
            height: 20px;
          }
          .listing-title {
            margin: 0;
          }
        }

        .budget-listings.feed.section {
        }
      }
    }

    .main-content-wrapper {
      display: flex;
      position: relative;
      padding: 0 4%;

      .main-content {
        margin: 0 auto;

        p.author {
          margin-bottom: 40px;
        }

        .main-copy {
          margin-bottom: 4rem;
          p:last-child {
            margin-bottom: 0;
          }
        }

        @include larger-than-phone {
          width: 640px;
          margin-left: 50px;
        }
      }

      .info {
        @include larger-than-phone {
          width: 220px;
          margin-left: 145px;
          font-family: $title-font;
          font-size: 12px;
          letter-spacing: 0.5px;

          ul {
            list-style: none;
            margin: 0;

            li {
              margin: 0;
              padding: 12px 0;

              &:not(:last-child) {
                border-bottom: 1px solid #c3c3c3;
              }

              .field {
                padding: 0;
                margin: 0;
                font-style: italic;
                display: inline-block;
                width: 56px;
                color: #4a4a4a;
              }

              .value {
                display: inline-block;
                color: $colors--primary-text;

                p {
                  padding: 0;
                  margin: 0;
                  font-family: $title-font;
                  font-size: 12px;
                  letter-spacing: 0.5px;
                }
              }
            }
          }
        }
      }

      .publish-date:not(.mobile) {
        position: absolute;
        // vertically rotate text and align at top left of box
        transform: rotate(270deg) translateX(calc(-100% + 4rem))
          translateY(100%);
        transform-origin: 0%, 100%;
        font-weight: bold;
        font-size: 2.4rem;
        line-height: 4rem;
      }

      .social-sharing-widget {
        h3 {
          flex-direction: row;

          .social-share-widget-item {
            margin-right: 12px;
            cursor: pointer;
            &:last-child {
              margin-bottom: 0;
            }
            &.twitter {
              opacity: 0.35;
            }
            svg {
              height: 12px;
            }
          }
        }
      }

      h1.title {
        margin-bottom: 0.4rem;
      }
    }

    .copy {
      width: 100%;
      padding: 0 4%;
      margin: 0 auto 4rem;

      font-family: "Times New Roman", serif;
      letter-spacing: 0.025rem;
      font-size: 1.6rem;
      line-height: 2.2rem;

      @include larger-than-phone {
        width: 720px;
        padding: 0;
        margin: 0 auto 8rem;
      }
    }

    .image {
      margin-bottom: 4rem;

      .caption {
        padding: 0 4%;
        p {
          font-size: 11px;
          font-family: $title-font;
          color: $colors--gray-banner;
          font-style: italic;
        }
      }

      @include larger-than-phone {
        margin-bottom: 8rem;
        .caption {
          padding: 0;
        }
      }
    }

    .quote {
      margin-bottom: 10rem;
      // -webkit-font-smoothing: initial;
      font-family: $title-font;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 4.2rem;
      position: relative;
      padding: 0 8.8rem;
      letter-spacing: -0.5px;

      blockquote {
        text-align: center;
        max-width: 1024px;
        width: 1024px;
        display: inline-block;
        margin: 0px;
      }

      .left-quote {
        position: absolute;
        font-size: 6rem;
        left: 2rem;
        top: 0;
      }

      .right-quote {
        position: absolute;
        font-size: 6rem;
        right: 2rem;
        bottom: -2.2rem;
      }
    }

    .listings-title {
      font-family: $title-font;
      font-weight: 700;
      font-size: 3.2rem;
      text-align: center;
      margin-bottom: 4rem;
    }

    .listings-caption {
      margin-bottom: 2rem;
      font-family: $control-font;
      font-weight: bold;
      font-size: 1.6rem;
      margin-left: 8rem;
    }
  }

  //
  // BASE ARTICLE END
  //

  //
  // LOOKS ARTICLE START
  //

  .article.looks {
    label.hero-label {
      margin-bottom: 6rem;
      font-family: $title-font;
      font-size: 1.1rem;
      line-height: 1.1rem;
      font-style: italic;
      color: $colors--gray-banner;
    }

    .looks-wrapper {
      .look {
        margin-bottom: 8rem;

        &:last-child {
          margin-bottom: 0;
        }

        img.hero {
          margin-bottom: 20px;
        }

        h1.title {
          text-transform: none;
          margin-bottom: 1.8rem;
          font-family: $title-font;
          font-size: 24px;
          font-weight: 800;
        }

        .looks-section-image {
          width: 96%;
          margin: 0 auto;
        }

        label.caption {
          p {
            font-family: "Times New Roman", serif;
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: 0.025rem;
            font-weight: 400;

            a {
              &:hover {
                border-bottom: 1px solid $colors--gray-banner;
              }
            }
          }
        }
      }
    }
  }

  //
  // LOOKS ARTICLE END
  //

  // THE DROP ARTICLE START
  //

  .article.the-drop {
    .drop-item {
      margin-bottom: 90px;

      .carousel.section {
        margin-bottom: 40px !important;
      }

      .title {
        text-align: center;
        font-family: $title-font;
        font-size: 1.8rem;
        letter-spacing: -0.5px;
        margin-bottom: 2rem;

        strong {
          display: inline-block;
        }

        @include larger-than-phone {
          letter-spacing: -1px;
          font-size: 48px;
          margin-bottom: 6rem;
          line-height: 56px;
        }
      }

      .content-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 8rem;

        @include larger-than-phone {
          flex-direction: row;
        }

        .info {
          font-size: 14px;
          letter-spacing: 0.5px;
          margin: 0 0 4rem;
          width: 100%;
          padding: 0 4%;

          ul {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            list-style: none;
            margin: 0 0 4rem 0;
            border-bottom: 1px solid #c3c3c3;
            padding-bottom: 1.4rem;

            li {
              border: 0;
              padding: 0;
              margin: 0;

              .field,
              .value {
                font-size: 1.2rem;
                width: auto;
                padding-right: 1rem;
              }
            }
          }

          @include larger-than-phone {
            width: 260px;
            margin-right: 80px;

            ul {
              border: 0;
              padding: 0;

              li {
                padding: 12px 0;
                border-bottom: 1px solid #c3c3c3;

                .field {
                  padding: 0;
                  margin: 0;
                  display: inline-block;
                  width: 56px;
                  color: #4a4a4a;
                }

                .value {
                  display: inline-block;
                  font-weight: bold;
                  text-transform: capitalize;
                  color: $colors--primary-text;
                }
              }
            }

            .buy-button {
              border-radius: 1.5px;
              border: 2px solid #4a4a4a;
              font-family: $title-font;
              font-size: 28px;
              background: $colors--white-background;
              font-weight: bold;
              letter-spacing: -1px;
              width: 100%;
              display: inline-block;
              text-align: center;
              line-height: 1;
              padding: 14px 0;

              &:hover {
                background: #4a4a4a;
                color: $colors--white-background;
              }
            }
          }
        }

        .copy {
          width: 100%;
          padding: 0 4%;

          p {
            font-size: 1.6rem;
            line-height: 2.4rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          @include larger-than-phone {
            width: 480px;
            margin: 0px;

            p {
              line-height: 1.5;
            }
          }
        }
      }
    }
  }

  //
  // THE DROP ARTICLE END
  //

  //
  // INVENTORY START
  //
  .listing-inventory {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;

    .listing-details-wrapper {
      padding-left: 5%;
      padding-right: 5%;
    }

    .listing-cta {
      margin: auto;
      width: 100%;
    }

    .listing-metadata {
      font-family: $title-font;
      font-size: 48px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $colors--primary-text;
      margin: auto;
      text-align: center;
      & > span.listing-title {
        font-weight: normal;
        margin: 0;
      }
    }

    .listing-price {
      font-family: $title-font;
      font-size: 48px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: center;
      color: $colors--primary-text;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .listing-size.sub-title.sizes {
      display: flex;
      flex-wrap: wrap;
      & > div {
        margin: auto;
      }
    }

    .purchase-button-container {
      display: flex;

      button.purchase {
        display: flex;
        height: 80px;
        line-height: 38px;
        margin: auto;
        width: 100%;
        border-radius: 2px;
        border: solid 2px $colors--gray-banner;
        font-family: $title-font;
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: center;
        color: $colors--primary-text;
        background-color: $colors--off-white-background;
        text-transform: none;
        &:disabled:hover {
          box-shadow: none;
        }
        span {
          margin: auto;
        }
      }
    }

    .inv-sold-out {
      color: $colors--medium-stroke;
    }

    .inventory-warning {
      width: 100%;
      height: 24px;
      margin-left: 2%;
      font-family: $title-font;
      font-size: 18px;
      font-weight: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: $colors--alert;
    }

    .designer {
      font-family: $title-font;
      text-transform: capitalize;
      letter-spacing: -1px;
      font-size: 45px;
    }

    .size-selection-button-container {
      display: flex;
      justify-content: space-around;
      margin-top: 60px;

      & > button {
        margin-left: 4px;
        margin-right: 4px;
        padding-left: 4px;
        padding-right: 4px;
        padding-top: 0px;
        padding-bottom: 0px;
        min-width: 66px;
        width: auto;
        height: 48px;
        border-radius: 1px;
        border: solid 1px $colors--gray-banner;
        background-color: $colors--off-white-background;
        color: $colors--primary-text;
        font-family: $title-font;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.89;
        letter-spacing: normal;
        text-align: center;

        &.selected {
          cursor: default;
          background-color: $colors--primary-text;
          color: $colors--off-white-background;
        }

        &:disabled:hover {
          box-shadow: none;
        }
      }
    }
  }
  //
  // INVENTORY END
  //
}

@media only screen and (min-device-width: 0px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  #blog .social-sharing-widget {
    display: block;
  }

  #blog {
    //
    // HOME PAGE START
    //

    .home {
      .main {
        width: 100%;
        padding-top: 0;
      }

      a.primary-article {
        margin-bottom: 1px;
      }

      .primary-article .primary-article-content {
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    .section {
      margin-bottom: 6rem;
    }

    .mobile {
      .carousel.section,
      img.hero.section {
        margin-bottom: 9px !important;
      }
    }

    .push {
      margin-top: 2rem;
    }

    .home,
    .tagged-articles {
      .primary-article-content {
        h2.caption {
          font-size: 1.2rem;
        }
      }

      a.primary-article {
        h1 {
          font-size: 2.2rem;
          line-height: 2.6rem;
          margin-bottom: 0;
          max-width: 40ch;
        }
        h2 {
          font-size: 1.2rem;
        }
      }
      a.primary-article {
        .gradient {
          display: none;
        }

        .only-mobile {
          display: block;
          position: absolute;
          bottom: 10%;
          left: 0;
          right: 0;
          margin-bottom: 0;
          z-index: $z-base-value;
        }
      }

      .content-wrapper {
        margin: 0;

        .primary-article-content,
        a.primary-article-content {
          display: none;
        }
      }
    }

    //
    // HOME PAGE END
    //

    // BASE ARTICLE START
    .article-wrapper {
      .main-content-wrapper h1.title {
        font-size: 1.8rem;
        line-height: 2rem;
        margin-bottom: 1.6rem;
      }

      .main-content-wrapper .author {
        font-size: 12px;
        margin-bottom: 2.4rem !important;
      }

      .hero-label {
        margin-bottom: 4rem;
      }

      h1.title {
        font-size: 18px;
        margin-bottom: 6px;
        line-height: 18px;
      }

      .carousel {
        width: 100vw !important;
        height: 66.66666666666667vw !important;

        margin-bottom: 2rem;

        .label {
          margin-left: 4%;
          margin-bottom: 4vw;
        }

        .slider.images {
          margin-bottom: 0.8rem;
        }

        .slider-slide {
          padding: 0 0;

          img {
            width: 100vw !important;
            height: 66.66666666666667vw !important;
          }
        }
      }

      .tags {
        margin-bottom: 7.5% !important;
      }

      .listings {
        margin-bottom: 0;
      }

      .budgets {
        flex-wrap: wrap;
        width: 100%;

        .budget {
          flex: initial;

          .budget-listings.feed.section {
            padding: 0;

            .feed {
              width: 100%;
              padding: 0;
            }
          }
        }
      }

      .quote {
        margin-bottom: 6rem;
        font-size: 2.4rem;
        line-height: 3.2rem;
        padding: 0;
        text-align: center;

        blockquote {
          max-width: 320px;
          width: 320px;
        }

        .left-quote {
          font-size: 2.4rem;
        }

        .right-quote {
          font-size: 2.4rem;
          bottom: -1.2rem;
        }
      }
    }
    // BASE ARTICLE END

    .article-wrapper .main-content-wrapper {
      .main-content {
        margin: 0;
      }
    }

    .article.long .tags {
      padding: 0 2%;
    }

    .article {
      h1.title {
        font-size: 1.4rem;
        line-height: 1.2rem;
        margin-bottom: 1.5rem;
      }
      .publish-date {
        font-size: 1.2rem;
        line-height: 1rem;
        margin-bottom: 3rem;
      }
      .main-copy {
        margin-bottom: 2rem;
      }
      .tags {
        font-size: 1.2rem;
        margin-bottom: 6rem;
      }

      .listings.feed {
        padding: 0 2%;

        .row {
          justify-content: initial;
        }

        .feed-item {
          margin-right: 2%;
        }
      }
    }

    .article.looks {
      .looks-wrapper {
        .look {
          margin-bottom: 5rem;
          .title {
            text-transform: uppercase;
            padding: 0 2%;
            line-height: 30px;
            word-break: break-word;
          }

          img.hero {
            margin-bottom: 2%;
          }

          label.caption p {
            line-height: 2rem;
          }
        }
      }
    }

    //
    // INVENTORY START
    //
    .listing-inventory {
      flex-direction: column;
      .listing-price,
      .listing-metadata,
      .purchase-button-container button span {
        font-size: 2.4rem;
      }
    }
    //
    // INVENTORY END
    //
  }
}

@media only screen and (min-device-width: 667px) and (max-device-width: 1057px) and (-webkit-min-device-pixel-ratio: 2) {
  #blog .social-sharing-widget {
    display: block;
  }

  .section {
    margin-bottom: 6rem;
  }

  #blog {
    .home,
    .tagged-articles {
      .primary-article-content {
        margin-bottom: 4rem;
      }

      a.primary-article {
        .gradient {
          display: none;
        }
      }

      .content-wrapper {
        .primary-article-content {
          h1 {
            font-size: 3.2rem;
            line-height: 3.8rem;
            margin-bottom: 0.5rem;
          }
          h2 {
            font-size: 1.6rem;
          }
        }
      }
    }

    .article {
      &.long .tags {
        padding: 0 2%;
      }

      .publish-date {
        left: 2%;
      }
    }

    .article.looks {
      img.hero {
        margin-bottom: 35px;
      }

      .looks-wrapper {
        .look {
          margin-bottom: 5rem;
          .title {
            text-transform: uppercase;
            padding: 0 2%;
            line-height: 30px;
            word-break: break-word;
          }

          img.hero {
            margin-bottom: 2%;
          }

          label.caption p {
            line-height: 2rem;
          }
        }
      }
    }
  }
}
