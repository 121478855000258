@import "../media-queries/index";
@import "realSpanWidth";
@import "default-config";
@import "guides";

@mixin grid--wrapper($config: $grid--default-config) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 92%;

  max-width: grid--realSpanWidth(1, 1, map-get($config, "mobile"));
  @include larger-than-phone { max-width: grid--realSpanWidth(1, 1, map-get($config, "tablet")); }
  @include larger-than-tablet { max-width: grid--realSpanWidth(1, 1, map-get($config, "laptop")); }
  @include larger-than-laptop { max-width: grid--realSpanWidth(1, 1, map-get($config, "desktop")); }

  &.___show-guides {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.35;

      @include grid--guides(map-get($config, "mobile"));
      @include larger-than-phone { @include grid--guides(map-get($config, "tablet")); }
      @include larger-than-tablet { @include grid--guides(map-get($config, "laptop")); }
      @include larger-than-laptop { @include grid--guides(map-get($config, "desktop")); }
    }
  }
}
