@import "storefront/components/styles/pxToRem.module.scss";

.subItem {
  color: var(--colors--primary-text);
  height: 4rem;
  opacity: 1;
  display: block;
  text-transform: uppercase;
  line-height: pxToRem(42);
  text-decoration: none;
  transition: opacity 250ms ease, height 250ms linear;
  font-family: "SF Pro Text", -apple-system, Helvetica, Arial, "Segoe UI",
    Roboto, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;

  a:hover {
    color: var(--colors--gray-banner);
    cursor: pointer;
  }

  &.signOut {
    color: var(--colors--secondary-text);
  }
}
