@import "mixins/media-queries/index";

.box {
  position: relative;
  width: fit-content;

  .link {
    text-decoration: none;
  }

  .button {
    border-color: var(--colors--primary-text);
    min-width: 75px;
  }

  .buttonText {
    text-transform: uppercase;
  }
}
