@import "mixins/media-queries/index";

$search-icon-size: 2rem;
$input-height: 4rem;
$clear-icon-size: 1.4rem;

.desktopSearch {
  display: none;
  @include larger-than-tablet {
    display: block;
  }
}

.mobileSearch {
  display: block;
  @include larger-than-tablet {
    display: none;
  }
}

.mobileContent {
  position: fixed;
  top: 0;
  z-index: 9999;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.32, 0.72, 0, 1);
  width: 100vw;
}

.wrapper {
  position: relative;
  width: 100%;
  @include larger-than-tablet {
    max-width: 600px;
  }
}

.input {
  border: 1px solid var(--colors--primary-text);
  height: $input-height;
  border-radius: .2rem;
  padding-left: calc(1.6rem + $search-icon-size + 0.8rem);

  @media (max-width: 669px) {
    &:focus-visible {
      box-shadow: none;
      outline: none;
    }
  }

}

.search {
  position: absolute;
  width: $search-icon-size;
  height: $search-icon-size;
  top: calc(($input-height - $search-icon-size) / 2);
  left: 1.6rem;

  svg {
    width: 100%;
    height: 100%;
  }
}

.searchButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
}

.clear {
  position: absolute;
  width: $clear-icon-size;
  height: $clear-icon-size;
  top: calc(($input-height - $clear-icon-size) / 2);
  left: 1.6rem;

  svg {
    width: 100%;
    height: 100%;
  }
}

.button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  display: flex;
}

.results {
  width: var(--radix-popover-trigger-width);
  z-index: 9999;
  box-sizing: border-box;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.result {
  position: relative;
  width: var(--radix-popover-trigger-width);
  display: flex;
  transition: 250ms background-color ease-in-out;
  padding: 0.8rem 1.6rem;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
  cursor: pointer;

  &:hover,
  &:focus,
  &[data-active-item="true"] {
    background-color: var(--colors--light-stroke);
  }

  color: inherit;
  text-decoration: none;
}

.resultSection {
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
}

.resultSectionHeader {
  margin-bottom: 1.6rem;
}

.delete {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
}
