@import "storefront/components/styles/pxToRem.module.scss";

.header {
  line-height: pxToRem(42);
  margin-top: pxToRem(12);
  text-transform: uppercase;
}

.username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
