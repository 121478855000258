@import "mixins/media-queries/index";
@import "mixins/type/index";
@import "stylesheets/shared/z-index";
@import "storefront/components/styles/pxToRem.module.scss";

.Page-Header-CategoriesNavigation {
  $transition-duration: 200ms;
  $transition-enter-delay: 50ms;
  $transition-exit-delay: 50ms;
  $mobile-header-height: 76px;

  display: none;
  position: relative;

  // ensures the nav gets hidden behind the main header on collapse
  z-index: $z-header-base - 1;

  padding: pxToRem(12) 0;
  background-color: var(--colors--white-background);
  top: $mobile-header-height;

  border-bottom: 1px solid var(--colors--light-stroke);

  @include larger-than-tablet {
    display: block;
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
  }

  @include larger-than-laptop {
    padding-left: pxToRem(35);
    padding-right: pxToRem(35);
  }

  .-outer {
    width: 100%;
    max-width: pxToRem(1200);
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  .-category {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: pxToRem(25);

    &:not(._designers) {
      position: relative;
    }

    &:hover {
      .-dropdown {
        opacity: 1;
        visibility: visible;
        transition: opacity $transition-duration ease $transition-enter-delay,
          visibility 0ms linear $transition-enter-delay;
      }
    }

    &._designers {
      .-title {
        width: 17%;
        text-align: left;
      }

      .--subcategories {
        flex: 1 1 40%;
        max-height: pxToRem(375);
      }
    }
  }

  .--link {
    @include type--primary-link;
    text-decoration: none;
    display: inline;
    vertical-align: middle;

    a {
      color: var(--colors-primary-text);
      text-decoration: none;
    }

    &:hover,
    a:hover {
      color: var(--colors--blue-highlight);
    }
  }

  .-dropdown {
    position: absolute;
    top: pxToRem(39);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    box-sizing: border-box;
    padding: pxToRem(42) pxToRem(60);
    max-width: pxToRem(1200);
    width: 100%;
    background-color: var(--colors--white-background);
    border: 1px solid var(--colors--light-stroke);
    opacity: 0;
    visibility: hidden;
    transition: opacity $transition-duration ease $transition-exit-delay,
      visibility 0ms linear ($transition-duration + $transition-exit-delay);

    &._category {
      width: pxToRem(185);
      top: pxToRem(32);
      display: block;
      left: pxToRem(-15);
      transform: none;
      padding: pxToRem(15) 0;

      .-link {
        @include type--primary-link;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 pxToRem(15);

        &:hover {
          color: var(--colors--blue-highlight);
        }

        .-arrow {
          transform: rotate(270deg);
        }
      }

      .-link:not(:first-child) {
        padding-top: pxToRem(20);
      }
    }

    .--subcategories-title {
      display: inline-block;
      @include type--body-editorial;
      text-decoration: none;
      text-transform: capitalize;

      &:hover {
        color: var(--colors--blue-highlight);
      }

      & + .--subcategory {
        margin-top: pxToRem(12);
      }
    }

    .--subcategory {
      display: inline-block;
      @include type--body;

      &:hover {
        color: var(--colors--blue-highlight);
      }

      & + .--subcategory {
        margin-top: pxToRem(8);
      }
    }

    .--subcategories {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 17%;
      text-align: left;
    }
  }
}
