@import "mixins/media-queries/index";
@import "stylesheets/shared/z-index";
@import "../../../../styles/pxToRem.module.scss";

.root {
  box-sizing: border-box;
  height: pxToRem(38);
  display: flex;
  justify-content: space-between;
  width: calc(100vw - pxToRem(225));
  border: 1px solid var(--colors--primary-text);
  border-radius: pxToRem(2);
  position: absolute;
  left: pxToRem(110);
  top: pxToRem(5.4);
  background-color: white;

  &.focused {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
    left: pxToRem(13);
    width: calc(100vw - pxToRem(25));
    z-index: 100;

    @include larger-than-phone {
      left: initial;
      width: 100%;
    }
  }

  @include larger-than-phone {
    position: relative;
    left: initial;
    top: initial;
    width: 100%;
    height: pxToRem(42);
    max-width: pxToRem(400);
    margin: 0 0 0 pxToRem(32);
  }

  @include larger-than-tablet {
    max-width: pxToRem(550);
  }
}

.iconButton {
  z-index: $z-links;
  display: flex;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0 pxToRem(10);
  width: auto;

  justify-content: center;
  align-items: center;

  background-color: transparent;

  @include larger-than-phone {
    margin: 0;
    width: pxToRem(45);
  }
}
