.avatar {
  height: 100%;
  width: 100%;
}

.smallFallback {
  font-size: 8px;
}

.mediumFallback {
  font-size: 14px;
}

.largeFallback {
  font-size: 20px;
}
