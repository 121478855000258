@import "../../../../../app/assets/stylesheets/mixins/media-queries/index";
@import "../../../../../app/assets/stylesheets/mixins/type/index";
@import "../../../../../app/assets/stylesheets/shared/z-index";
@import "../../../../../app/assets/stylesheets/functions/math";
@import "../../../styles/pxToRem.module.scss";

.Page-Header-SlideOutMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-header-base;
  background-color: transparent;
  visibility: hidden;
  transition-property: background-color, visibility;
  transition-duration: 250ms, 0ms;
  transition-timing-function: ease;
  transition-delay: 0ms, 250ms;

  &._open {
    background-color: fade-out($color: black, $amount: 0.5);
    visibility: visible;
    transition-delay: 0ms, 0ms;

    .--close {
      opacity: 1;
    }

    .--drawer {
      transform: translateX(0);
    }
  }

  .--close {
    position: fixed;
    top: pxToRem(5);
    right: pxToRem(5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: pxToRem(50);
    height: pxToRem(50);
    border: 0;
    background-color: transparent;
    opacity: 0;
    transition: opacity 250ms ease;

    svg {
      height: pxToRem(30);
      width: pxToRem(30);
    }
  }

  .--drawer {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 85%;
    max-width: pxToRem(320);
    height: 100%;
    background-color: white;
    transition: transform 250ms ease;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .--item {
    & + .--item {
      border-top: 1px solid var(--colors--light-stroke);
    }

    &._has-children {
      .--label:after,
      .--label:before {
        display: block;
      }
    }

    &._has-image {
      .--label {
        display: flex;
        align-items: center;
        padding-top: pxToRem(17);
        padding-bottom: pxToRem(17);
      }
    }

    &._open {
      .--label {
        border-bottom-color: var(--colors--light-stroke);
        transition: border-bottom-color 0ms linear 0ms;

        &:after {
          transform: rotate(132deg);
        }
        &:before {
          transform: rotate(-132deg);
        }
      }

      > .--sub-items {
        max-height: 1200px;
        padding-bottom: pxToRem(16);
      }

      .--sub-item,
      .SubItemHeader {
        height: pxToRem(42);
        opacity: 1;
      }

      .--nested-sub-items {
        max-height: 1200px;

        .--label {
          border-bottom-color: transparent;
        }

        .--sub-item {
          height: 0;
        }

        .--item._open {
          .--label {
            border-bottom-color: var(--colors--light-stroke);
          }

          .--sub-item {
            height: pxToRem(42);
            opacity: 1;
          }
        }
      }
    }

    > h2 {
      margin: 0;
    }

    .--label {
      position: relative;
      display: block;
      padding: pxToRem(20);
      background-color: white;
      border-bottom: 1px solid transparent;
      @include type--headline-2;
      text-decoration: none;
      transition: border-bottom-color 0ms linear 225ms;
      text-transform: capitalize;

      $arrow-height: 5;
      $arrow-width: 9;

      // When the item has children, we draw a small arrow. When the item is open, the arrow points
      // up; when the item is closed, the arrow points down.
      &:after,
      &:before {
        content: "";
        position: absolute;
        top: pxToRem(34);
        width: pxToRem(1);
        display: none;
        // We use the same technique as the close arrow above to calculate the length of the arms
        // of the arrow with one difference, each arm only extends half of the width of the arrow.
        height: sqrt(square($arrow-height) + square(($arrow-width) * 0.5)) * 1px;
        background-color: var(--colors--medium-stroke);
        transition: transform 250ms linear;
      }

      // Unlike the simple 45 degree angle in the X above, the arrow's arms are not in a perfect
      // square. To calculate the angle of the arms, I used inverse tangent, but you could have
      // used inverse sine or inverse cosine, too, because we know the lengths of all 3 sides.
      // Read more about inverse sine, cosine, and tangent here:
      // http://www.mathsisfun.com/algebra/trig-inverse-sin-cos-tan.html
      &:after {
        right: pxToRem(30);
        transform: rotate(48deg);
      }
      &:before {
        right: pxToRem(35);
        transform: rotate(-48deg);
      }

      .--image {
        display: inline-block;
        width: pxToRem(35);
        height: pxToRem(35);
        border-radius: 100%;
        margin-right: pxToRem(12);
        vertical-align: -pxToRem(11);
        background-position: center;
        background-size: cover;
      }
    }

    .--sub-items {
      display: block;
      padding: 0 pxToRem(20);
      background-color: var(--colors--white-background);
      overflow: hidden;
      max-height: 0;
      transition: opacity 250ms ease, max-height 250ms linear;
    }

    .--nested-sub-items {
      display: block;
      overflow: hidden;
      max-height: 0;
      transition: opacity 250ms ease, max-height 250ms linear;

      .--label {
        padding-left: 4rem;
      }

      .--sub-item {
        padding-left: 2rem;
      }
    }
  }

  .--sub-item {
    color: var(--colors--primary-text);
    display: block;
    font-size: 12px;
    height: 0;
    line-height: pxToRem(42);
    opacity: 0.25;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 250ms ease, height 250ms linear;

    &:hover {
      opacity: 0.55;
    }

    &._active {
      text-decoration: underline;
    }

    &._notifications {
      &:after {
        content: "";
        display: inline-block;
        width: pxToRem(14);
        height: pxToRem(14);
        margin-left: pxToRem(10);
        border-radius: 100%;
        background-color: var(--colors--alert);
        vertical-align: -pxToRem(3);
      }
    }
  }

  .SubItemHeader {
    height: 0;
    opacity: 0.25;
    transition: opacity 250ms ease, height 250ms linear;
  }
}
