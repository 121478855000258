@import "mixins/type/index";
@import "storefront/components/styles/pxToRem.module.scss";

.dropdown {
  $transition-duration: 200ms;
  $transition-enter-delay: 50ms;
  $transition-exit-delay: 50ms;

  position: absolute;
  top: pxToRem(39);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: pxToRem(42) pxToRem(60);
  max-width: pxToRem(1200);
  width: 100%;
  background-color: var(--colors--white-background);
  border: 1px solid var(--colors--light-stroke);
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-duration ease $transition-exit-delay,
    visibility 0ms linear ($transition-duration + $transition-exit-delay);
}

.category {
  $transition-duration: 200ms;
  $transition-enter-delay: 50ms;
  $transition-exit-delay: 50ms;

  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: pxToRem(25);

  &:hover {
    .dropdown {
      opacity: 1;
      visibility: visible;
      transition: opacity $transition-duration ease $transition-enter-delay,
        visibility 0ms linear $transition-enter-delay;
    }
  }
}

.link {
  @include type--primary-link;
  font-weight: 600;
  text-decoration: none;
  display: inline;
  vertical-align: middle;

  a {
    color: var(--colors-primary-text);
    text-decoration: none;
  }

  &:hover,
  a:hover {
    color: var(--colors--blue-highlight);
  }
}
